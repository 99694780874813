var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.vipProfileComplete.profile
    ? _c("div", [
        _c(
          "h4",
          {
            staticClass: "h4-profile-edit",
          },
          [_vm._v(_vm._s(_vm.$t("vip.edit_profile")))]
        ),
        _vm.vipProfileComplete.profile.photo
          ? _c(
              "div",
              {
                staticClass: "rounded-circle are-profile-photo",
              },
              [
                _vm.vipProfileComplete.profile.photo === ""
                  ? _c("img", {
                      staticClass: "img-profile-photo",
                      attrs: {
                        id: "image",
                        src: "https://cdn2.wuwow.tw/images/kidpic.png",
                      },
                    })
                  : _c("img", {
                      staticClass: "img-profile-photo",
                      attrs: {
                        id: "image",
                        src: _vm.vipProfileComplete.profile.photo,
                      },
                    }),
              ]
            )
          : _c("span", [
              _vm._v(
                _vm._s(_vm.$t("please")) +
                  _vm._s(_vm.$t("upload")) +
                  _vm._s(_vm.$t("photo"))
              ),
            ]),
        _c("form", [
          _c(
            "div",
            {
              staticClass: "are-profile-fileupload",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary btn-file-photo",
                },
                [_vm._v(_vm._s(_vm.$t("upload")) + _vm._s(_vm.$t("photo")))]
              ),
              _c("input", {
                staticClass: "ipt-file-photo",
                attrs: {
                  id: "image_file_upload_input",
                  type: "file",
                  name: "file",
                  accept: "image/jpeg,image/jpg,image/png",
                },
                on: {
                  change: function ($event) {
                    return _vm.getImage()
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "are-profile-input",
            },
            [
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                      attrs: {
                        for: "account",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("account")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vipProfileComplete.profile.account,
                            expression: "vipProfileComplete.profile.account",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          id: "account",
                          type: "text",
                          readonly: "",
                        },
                        domProps: {
                          value: _vm.vipProfileComplete.profile.account,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.vipProfileComplete.profile,
                              "account",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [_vm._v(_vm._s(_vm.$t("old_password")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.passwordObject.oldPassword,
                            expression: "passwordObject.oldPassword",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          placeholder:
                            _vm.$t("please") +
                            _vm.$t("enter") +
                            _vm.$t("old_password"),
                          type: "password",
                        },
                        domProps: {
                          value: _vm.passwordObject.oldPassword,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.passwordObject,
                              "oldPassword",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [_vm._v(_vm._s(_vm.$t("new_password")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.passwordObject.newPassword,
                            expression: "passwordObject.newPassword",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          placeholder:
                            _vm.$t("please") +
                            _vm.$t("enter") +
                            _vm.$t("new_password"),
                          type: "password",
                        },
                        domProps: {
                          value: _vm.passwordObject.newPassword,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.passwordObject,
                              "newPassword",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [_vm._v(_vm._s(_vm.$t("confirm_password")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.passwordObject.passwordConfirmation,
                            expression: "passwordObject.passwordConfirmation",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          placeholder:
                            _vm.$t("please") +
                            _vm.$t("confirm") +
                            _vm.$t("new_password"),
                          type: "password",
                        },
                        domProps: {
                          value: _vm.passwordObject.passwordConfirmation,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.passwordObject,
                              "passwordConfirmation",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [_vm._v(_vm._s(_vm.$t("english_name")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vipProfileComplete.profile.english_name,
                            expression:
                              "vipProfileComplete.profile.english_name",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          type: "text",
                          readonly: "",
                        },
                        domProps: {
                          value: _vm.vipProfileComplete.profile.english_name,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.vipProfileComplete.profile,
                              "english_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("birthday"))),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [_vm._v("*")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("datepicker", {
                        staticStyle: {
                          width: "100%",
                        },
                        attrs: {
                          "bootstrap-styling": true,
                          format: "yyyy-MM-dd",
                          placeholder: _vm.$t("birthday"),
                        },
                        model: {
                          value: _vm.vipProfileComplete.profile.birthday,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.vipProfileComplete.profile,
                              "birthday",
                              $$v
                            )
                          },
                          expression: "vipProfileComplete.profile.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                      attrs: {
                        for: "gender",
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("gender"))),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [_vm._v("*")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.vipProfileComplete.profile.gender,
                              expression: "vipProfileComplete.profile.gender",
                            },
                          ],
                          staticClass: "custom-select ddl-profile",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.vipProfileComplete.profile,
                                "gender",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "",
                                disabled: "",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("please")) +
                                  _vm._s(_vm.$t("select")) +
                                  _vm._s(_vm.$t("gender"))
                              ),
                            ]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "F",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("female")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "M",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("male")))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("learning_target"))),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [_vm._v("*")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.vipProfileComplete.profile.learning_target,
                              expression:
                                "vipProfileComplete.profile.learning_target",
                            },
                          ],
                          staticClass: "custom-select ddl-profile",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.vipProfileComplete.profile,
                                "learning_target",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "-1",
                                disabled: "",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("please")) +
                                  _vm._s(_vm.$t("select")) +
                                  _vm._s(_vm.$t("learning_target"))
                              ),
                            ]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "work",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("work")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "personal",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("personal")))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("highest_education"))),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [_vm._v("*")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.vipProfileComplete.education.education,
                              expression:
                                "vipProfileComplete.education.education",
                            },
                          ],
                          staticClass: "custom-select ddl-profile",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.vipProfileComplete.education,
                                "education",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "",
                                disabled: "",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("please")) +
                                  _vm._s(_vm.$t("select")) +
                                  _vm._s(_vm.$t("education"))
                              ),
                            ]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "children",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("under_elementary")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "elementary_school",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("elementary_school")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "junior_high_school",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("junior_high_school")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "high_school",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("high_school")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "bachelor",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("bachelor")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "master",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("master")))]
                          ),
                          _c(
                            "option",
                            {
                              attrs: {
                                value: "doctor",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("doctor")))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("email"))),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [_vm._v("*")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vipProfileComplete.profile.email,
                            expression: "vipProfileComplete.profile.email",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          placeholder: _vm.$t("email"),
                          type: "text",
                        },
                        domProps: {
                          value: _vm.vipProfileComplete.profile.email,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.vipProfileComplete.profile,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                      attrs: {
                        for: "phone",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("work_phone")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vipProfileComplete.profile.work_phone,
                            expression: "vipProfileComplete.profile.work_phone",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          name: "phone",
                          placeholder: _vm.$t("numbers_only"),
                          type: "text",
                        },
                        domProps: {
                          value: _vm.vipProfileComplete.profile.work_phone,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.vipProfileComplete.profile,
                              "work_phone",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                      attrs: {
                        for: "home_phone",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("home_phone")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vipProfileComplete.profile.home_phone,
                            expression: "vipProfileComplete.profile.home_phone",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          name: "home_phone",
                          placeholder: _vm.$t("numbers_only"),
                          type: "text",
                        },
                        domProps: {
                          value: _vm.vipProfileComplete.profile.home_phone,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.vipProfileComplete.profile,
                              "home_phone",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group row",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label label-profile",
                      attrs: {
                        for: "address",
                      },
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("address"))),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                        },
                        [_vm._v("*")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-10",
                    },
                    [
                      _c(
                        "twzipcode",
                        {
                          ref: "twzipcode",
                          staticClass: "blk-profile-zipcode",
                          attrs: {
                            "class-names": {
                              county: "form-control ddl-profile",
                              district: "form-control ddl-profile",
                              zipcode: "form-control ddl-profile",
                            },
                            "default-zipcode":
                              _vm.vipProfileComplete.address.postal_code,
                          },
                          on: {
                            update: _vm.result,
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "blk-profile-address",
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.vipProfileComplete.address.region,
                            expression: "vipProfileComplete.address.region",
                          },
                        ],
                        staticClass: "form-control ipt-profile",
                        attrs: {
                          id: "address",
                          type: "text",
                        },
                        domProps: {
                          value: _vm.vipProfileComplete.address.region,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.vipProfileComplete.address,
                              "region",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "text-center",
                  attrs: {
                    variant: "warning",
                    show: "",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("vip.confidentiality_of_personal_information_mes")
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }