var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "reg-profile-edit",
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "wuwow-card-body",
            },
            [
              _c("porfile-edit", {
                ref: "porfile-edit",
                attrs: {
                  vipProfileComplete: _vm.vipProfileComplete,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "wuwow-card-footer text-center",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    disabled: !_vm.filedsAllFilled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.editPorfile()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }